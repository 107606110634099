import React, { useCallback, useEffect, useState } from "react";
import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import FetchData from "Components/Axios/FetchData";
import useSendData from "Components/Axios/SendData";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";
import validationSchema from './../validationSchema';

type Types = {
  handleClose: () => void;
  onAddSupply: any;
};

export default function AddFoodItem({ handleClose, onAddSupply }: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();
  const {
    data: categoriesData,
    error: fetchError,
    isLoading,
  } = FetchData("categories");

  const [categories, setCategories] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [selectedAddOn, setSelectedAddOn] = useState(null);
  const [selectedAddOnDetails, setSelectedAddOnDetails] = useState(null);
  const [images, setImages] = useState<File[]>([]);

  const {
    data: addOnsData,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isLoadingAddOns,
    error: fetchAddOnsError,
  } = FetchData("add_ons");

  useEffect(() => {
    if (addOnsData && Array.isArray(addOnsData)) {
      setAddOns(addOnsData);
    } else if (fetchAddOnsError) {
      toast.error("Error fetching add ons.");
    }
  }, [addOnsData, fetchAddOnsError]);

  // useEffect(() => {
  //   const addOn = addOns.find((addOn) => addOn.id === selectedAddOn);
  //   if (addOn) {
  //     formik.setValues({ ...formik.values, addOnData: addOn });
  //   }
  // }, [selectedAddOn, addOns]);

  useEffect(() => {
    if (categoriesData && Array.isArray(categoriesData)) {
      setCategories(categoriesData);
    } else if (categoriesData) {
      toast.error("Error fetching categories.");
    }
  }, [categoriesData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: null,
      name: "",
      description: "",
      price: "",
      category_id: "",
      selectedAddOnDetails: [],
      copied_add_ons_attributes: [
        {
          name: "",
          is_single_select: false,
          options_attributes: [],
          // options: [],
          // description: "",

          // options_attributes: [
          //   {
          //     name: "",
          //     is_per_unit: false,
          //     price: "",
          //   },
          // ],
        },
      ],

      addOnData: null,
      tax_applicable: true,
    },
    validationSchema,
    onSubmit: async (values) => {

      const formData = new FormData();
      if (values.image) {
        formData.append("food_item[image]", values.image);
      }
      // formData.append("image", values.image);
      formData.append("food_item[name]", values.name);
      formData.append("food_item[description]", values.description);
      formData.append("food_item[price]", values.price.toString());
      formData.append("food_item[category_id]", values.category_id);
      formData.append("food_item[tax_applicable]", values.tax_applicable.toString()); 

      // values.selectedAddOnDetails.forEach((addOn, index) => {
      //   formData.append(
      //     `food_item[copied_add_ons_attributes][${index}][name]`,
      //     addOn.name
      //   );
      //   formData.append(
      //     `food_item[copied_add_ons_attributes][${index}][is_single_select]`,
      //     addOn.is_single_select
      //   );
      // });

      // formData.append(
      //   `food_item[copied_add_ons_attributes][0][name]`,
      //   values.selectedAddOnDetails[0].name
      // );

      // formData.append(
      //   `food_item[copied_add_ons_attributes][0][is_single_select]`,
      //   values.selectedAddOnDetails[0].is_single_select
      // );

      // if (
      //   values.selectedAddOnDetails &&
      //   values.selectedAddOnDetails.length > 0
      // ) {
      //   const addOnDetails = values.selectedAddOnDetails[0];

      //   // Check if options exist and are an array
      //   if (addOnDetails.options && Array.isArray(addOnDetails.options)) {
      //     addOnDetails.options.forEach((option: any, optionIndex: any) => {
      //       // Append option name
      //       formData.append(
      //         `food_item[copied_add_ons_attributes][0][options_attributes][${optionIndex}][name]`,
      //         option.name
      //       );

      //       // Append option is_per_unit flag
      //       formData.append(
      //         `food_item[copied_add_ons_attributes][0][options_attributes][${optionIndex}][is_per_unit]`,
      //         option.is_per_unit.toString()
      //       );

      //       // Append option price
      //       formData.append(
      //         `food_item[copied_add_ons_attributes][0][options_attributes][${optionIndex}][price]`,
      //         option.price.toString()
      //       );
      //     });
      //   }
      // }

      const submittedData = await sendData({
        endpoint: "food_items",
        // data: formattedData,
        data: formData,
        // data: {
        //   ...formattedData,
        //   add_ons: selectedAddOnDetails ? [selectedAddOnDetails] : [],
        // },
        // isJson: false,
        method: "post",
      });
      if (submittedData) {
        onAddSupply(submittedData);
        handleClose();
      }
    },
  });

  // useEffect(() => {
  //   if (selectedAddOn && addOns.length > 0) {
  //     const detail = addOns.find((addOn) => addOn.id === selectedAddOn[0]);
  //     formik.setFieldValue("selectedAddOnDetails", detail ? [detail] : []);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedAddOn, addOns, formik.setFieldValue]);

  const handleImagesChange = useCallback(
    (newImages: File[]) => {
      setImages(newImages);
      formik.setFieldValue("images", newImages);
    },
    [formik]
  );

  // useEffect(() => {
  //   const addOnDetails = addOns.find((addOn) => addOn.id === selectedAddOn);
  //   setSelectedAddOnDetails(addOnDetails || null); // Store the details or null if not found
  // }, [selectedAddOn, addOns]);

  // const handleDropdownChange = (setterFunction: any) => (newValue: any) => {
  //   setterFunction(newValue);
  // };

  // const handleDropdownChangeCategory = handleDropdownChange(
  //   setSelectedCategories
  // );

  
  const errorToDisplay = fetchError || sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={isLoading || sendLoading} />
      </>
      <FormFields
        formik={formik}
        handleClose={handleClose}
        isSubmitting={formik.isSubmitting}
        formTitle={"Add New Food Item"}
        saveButtonLabel={"Create Food Item"}
        categories={categories}
        addOns={addOns}
        selectedAddOn={selectedAddOn}
        setSelectedAddOn={setSelectedAddOn}
        selectedAddOnDetails={selectedAddOnDetails}
        setSelectedAddOnDetails={setSelectedAddOnDetails}
        setImages={setImages}
        handleImagesChange={handleImagesChange}
        images={images}
        onUpdateSuccess={onAddSupply}
      />
    </>
  );
}
