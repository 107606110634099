import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  IconButton,
  Dialog,
  CardMedia,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { baseUrl } from "utils/baseUrl";
import ConfirmDelete from "Components/ConfirmDelete/ConfirmDelete";
import AddFoodItem from "./AddFoodItem/AddFoodItem";
import UpdateFoodItem from "./UpdateFoodItem/UpdateFoodItem";
import FoodItemsDetails from "./FoodItemsDetails/FoodItemsDetails";
import { useNavigate } from "react-router-dom";
import Loading from "Components/ErrorAndLoading/Loading";

interface Category {
  id: string;
  name: string;
  description: string;
  price: string;
  image_url: string;
  tax_applicable: boolean;
}

export default function FoodItems() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState("");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
  const [selectedSupply, setSelectedSupply] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      setisLoading(true);
      try {
        let token = localStorage.getItem("userToken");

        const response = await axios.get(`${baseUrl}/food_items`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setCategories(response.data);
      } catch (error) {
        toast.error("Error fetching categories");
      }
      setisLoading(false);
    };

    fetchCategories();
  }, []);

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenEditDialog = (category: Category) => {
    setSelectedCategory(category);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => setOpenEditDialog(false);

  const handleOpenDeleteDialog = (category: Category) => {
    setSelectedCategory(category);
    setOpenDeleteDialog(true);
  };

  const onUpdateSuccess = (updatedCategory: any) => {
    // First, check if updatedCategory is not undefined
    if (!updatedCategory) {
      console.error("Updated category data is undefined");
      return;
    }

    // Proceed with your logic if updatedCategory is defined
    setCategories((currentCategories) =>
      currentCategories.map((category) =>
        category.id === updatedCategory.id ? updatedCategory : category
      )
    );
    setOpenEditDialog(false);
  };
  const handleCloseViewDetails = () => {
    setViewDetailsOpen(false);
  };

  // const handleCategoryClick = (id: any) => {
  //   navigate(`/fooditems/${id}`);
  // };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleOpenViewDetails = (supplyId: any) => {
    // console.log("Opening details for supply:", supplyId);
    const supply = categories.find((s) => s.id === supplyId);
    setSelectedSupply(supply);
    setViewDetailsOpen(true);
  };

  const handleAddCategorySuccess = (newCategory: any) => {
    setCategories([...categories, newCategory]);
  };

  const handleFoodItemClick = (id: any) => {
    navigate(`/copiedaddons/${id}`);
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <>
          <Loading isLoading={isLoading} />
        </>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleOpenAddDialog}
            sx={{
              bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
              color: (theme) => theme.palette.custom?.buttonColor,
            }}
          >
            Add New Food Item
          </Button>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            minWidth: "63vw",
            "& > .MuiGrid-item": {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              minWidth: "300px",
            },
          }}
        >
          {categories
            .filter((category) =>
              category.name.toLowerCase().includes(searchText.toLowerCase())
            )
            .map((category) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={category.id}>
                <Card
                  sx={{
                    bgcolor: (theme) => theme.palette.custom?.card,
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={category.image_url || "default_image_path"}
                    alt={category.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {category.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {category.description}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      price: {category.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Tax Applicable: {category.tax_applicable ? "Yes" : "No"}
                    </Typography>
                  </CardContent>
                  <Grid container justifyContent="flex-end">
                    <IconButton
                      // onClick={() => handleOpenViewDetails(category.id)}
                      onClick={() => handleFoodItemClick(category.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>

                    <IconButton onClick={() => handleOpenEditDialog(category)}>
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => handleOpenDeleteDialog(category)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Box>

      <Dialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
          <AddFoodItem
            handleClose={handleCloseAddDialog}
            onAddSupply={handleAddCategorySuccess}
          />
        </Box>
      </Dialog>

      {openDeleteDialog && selectedCategory && (
        <ConfirmDelete
          itemName={selectedCategory.name}
          endpoint={`food_items/${selectedCategory.id}`}
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          onDeleted={() => {
            setCategories(
              categories.filter((supply) => supply.id !== selectedCategory.id)
            );
            setOpenDeleteDialog(false);
          }}
        />
      )}

      <Dialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
            color: (theme) => theme.palette.custom?.dialogColor,
          }}
        >
          {selectedCategory && (
            <UpdateFoodItem
              selectedCategory={selectedCategory}
              handleClose={handleCloseEditDialog}
              onUpdateSuccess={onUpdateSuccess}
            />
          )}
        </Box>
      </Dialog>

      <Dialog open={viewDetailsOpen} onClose={handleCloseViewDetails}>
        {selectedSupply && (
          <FoodItemsDetails
            foodItem={selectedSupply as any}
            open={viewDetailsOpen}
            handleClose={handleCloseViewDetails}
          />
        )}
      </Dialog>
    </>
  );
}
