import {
  Button,
  Box,
  Container,
  CssBaseline,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  FormLabel,
  FormGroup,
  Switch,
  Autocomplete,
  TextField,
} from "@mui/material";
// import Dropdown from "Components/Dropdown/Dropdown";
import CloseIcon from "@mui/icons-material/Close";
import useSendData from "Components/Axios/SendData";
import { AllowToNumberOnly } from "Components/AllowToNumberOnly/AllowToNumberOnly";
import CustomTextField from "Components/CustomTextField/CustomTextField";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export interface Types {
  formik: any;
  isSubmitting: boolean;
  handleClose: () => void;
  formTitle: string;
  saveButtonLabel: string;
  isEdit?: any;
  selectedCategory?: any;
  onUpdateSuccess?: any;
  addOns?: any;
}

type OptionType = {
  id?: string;
  idd?: string;
  name: string;
  is_per_unit: boolean;
  // is_mandatory: boolean;
  price: number;
  _destroy?: "1" | undefined;
};

const FormFields: React.FC<Types> = ({
  formik,
  isSubmitting,
  handleClose,
  formTitle,
  saveButtonLabel,
  isEdit,
  selectedCategory,
  onUpdateSuccess,
  addOns,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sendData, error, isLoading: sendLoading } = useSendData();
  // const [isImagesSelected, setIsImagesSelected] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addOnDetails, setAddOnDetails] = useState<any[]>([]);
  // const [selectedAddOn, setSelectedAddOn] = useState("");
  const [selectedAddOn, setSelectedAddOn] = useState<any | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleNumberChange = (fieldName: string, decimal: boolean = false) =>
    AllowToNumberOnly(
      (name: string, value: any) => {
        formik.setFieldValue(name, value);
      },
      fieldName,
      decimal
    );

  const handleAddOptionAttribute = () => {
    const newOption: OptionType = {
      id: uuidv4(),
      name: "",
      is_per_unit: false,
      // is_mandatory: false,
      price: 0,
      // _destroy: undefined,
    };

    const optionsWithoutDestroyed = formik.values.options_attributes.filter(
      (option: any) => option._destroy !== "1"
    );

    formik.setFieldValue("options_attributes", [
      ...optionsWithoutDestroyed,
      newOption,
    ]);
  };
  // Function to handle option attribute change
  const handleOptionAttributeChange = (id: any, field: any, value: any) => {
    const index = formik.values.options_attributes.findIndex(
      (option: any) => option.id === id
    );

    // Proceed only if a valid index is found
    if (index > -1) {
      const newOptions = [...formik.values.options_attributes];
      newOptions[index] = { ...newOptions[index], [field]: value };

      formik.setFieldValue("options_attributes", newOptions);
    }
  };

  const handleRemoveOptionAttribute = (idToRemove: any) => {
    const newOptions = formik.values.options_attributes.map((option: any) => {
      if (option.id === idToRemove) {
        return { ...option, _destroy: "1" };
      }
      return option;
    });

    // formik.setFieldValue("options_attributes", newOptions);
    formik.setFieldValue(
      "options_attributes",
      formik.values.options_attributes.filter(
        (option: any) => option.id !== idToRemove
      )
    );

    if (!selectedCategory) {
      return;
    }

    const formattedData = {
      options_attributes: newOptions,
    };

    sendData({
      endpoint: `copied_add_ons/${selectedCategory.id}`,
      data: formattedData,
      isJson: true,
      method: "put",
    })
      .then((response) => {
        // Handle the response
        onUpdateSuccess(response);
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error removing option:", error);
      });
  };

  const renderOptionAttributes = () => {
    if (!Array.isArray(formik.values.options_attributes)) {
      return null;
    }
    return (
      <>
        {formik.values.options_attributes.map((option: any) => (
          <Box key={option.id} sx={{ marginBottom: 2 }}>
            <CustomTextField
              name="name"
              label="Option Name"
              value={option.name}
              onChange={(e: any) =>
                handleOptionAttributeChange(option.id, "name", e.target.value)
              }
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Is Per Unit</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={option.is_per_unit}
                      onChange={(e) =>
                        handleOptionAttributeChange(
                          option.id,
                          "is_per_unit",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label={option.is_per_unit ? "Yes" : "No"}
                />
              </FormGroup>
            </FormControl>

            <CustomTextField
              name="Price"
              label="Price"
              type="number"
              value={option.price}
              onChange={(e: any) =>
                handleOptionAttributeChange(option.id, "price", e.target.value)
              }
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />
            <IconButton
              onClick={() => handleRemoveOptionAttribute(option.id)}
              aria-label="Remove"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
      </>
    );
  };

  // const handleSelectAddOn = (
  //   event: SelectChangeEvent<typeof selectedCategory>
  // ) => {
  //   const addOnIds = event.target.value as string[];
  //   const addOnDetailsWithOptions = addOns
  //     .filter((addOn: any) => addOnIds.includes(addOn.id.toString()))
  //     .map((addOn: any) => ({
  //       id: addOn.id,
  //       name: addOn.name,
  //       is_single_select: addOn.is_single_select || false,
  //       options: addOn.options || [],
  //     }));

  //     selectedCategory(addOnIds);
  //   setAddOnDetails(addOnDetailsWithOptions);
  //   formik.setFieldValue("selectedAddOn", addOnIds);
  //   formik.setFieldValue("selectedAddOnDetails", addOnDetailsWithOptions);
  // };

  const handleSelectAddOn = (addOn: any | null) => {
    if (addOn) {
      setSelectedAddOn(addOn);
      formik.setFieldValue("name", addOn.name);
      formik.setFieldValue("is_single_select", addOn.is_single_select);
      formik.setFieldValue("is_mandatory", addOn.is_mandatory);
      formik.setFieldValue(
        "options_attributes",
        addOn.options.map((option: any) => ({
          id: option.id,
          name: option.name,
          is_per_unit: option.is_per_unit,
          // is_mandatory: option.is_mandatory,
          price: option.price,
        }))
      );
    } else {
      setSelectedAddOn(null);
      formik.setFieldValue("name", "");
      formik.setFieldValue("is_single_select", false);
      formik.setFieldValue("options_attributes", []);
    }
  };

  useEffect(() => {
    if (selectedAddOn) {
      const selected = addOns.find(
        (addOn: any) => addOn.id === Number(selectedAddOn)
      );
      if (selected) {
        setAddOnDetails([
          {
            id: selected.id,
            name: selected.name,
            is_single_select: selected.is_single_select || false,
            options: selected.options || [],
          },
        ]);
      }
    }
  }, [selectedAddOn, addOns]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedAddOn(String(selectedCategory.id));
    }
  }, [selectedCategory]);

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        bgcolor: (theme) => theme.palette.custom?.dialogBgColor,
        color: (theme) => theme.palette.custom?.dialogColor,
      }}
    >
      <CssBaseline />
      <DialogContent>
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">{formTitle}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              // onClick={handleCloseAddDialog}
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <>
              {/* <FormControl fullWidth margin="normal">
                <InputLabel id="supplier-label">Add Ons</InputLabel>
                <Select
                  // multiple
                  fullWidth
                  sx={{ minWidth: "300px" }}
                  labelId="add-on-label"
                  label="Add Ons"
                  // value={selectedCategory}
                  value={selectedAddOn}
                  onChange={handleSelectAddOn}
                >
                  {addOns.map((addOn: any) => (
                    <MenuItem key={addOn.id} value={addOn.id}>
                      {addOn.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}

              <Autocomplete
                id="add-on-autocomplete"
                options={addOns}
                getOptionLabel={(option: any) => option.name || ""}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    fullWidth
                    label="Add Ons"
                    variant="outlined"
                  />
                )}
                value={selectedAddOn}
                onChange={(event, value: any | null) => {
                  handleSelectAddOn(value);
                }}
                fullWidth
                sx={{ minWidth: "100%" }}
              />

              <>
                {/* Description field */}
                {/* <CustomTextField
                name="description"
                label="Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              /> */}

                {/* <CustomTextField
                name="price"
                label="Price"
                // type="number"
                value={formik.values.price}
                // onChange={formik.handleChange}
                onChange={handleNumberChange("price", true)}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              /> */}
              </>
              {selectedAddOn && (
                <>
                  <CustomTextField
                    name="name"
                    label="Add Ons Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  <FormControl component="fieldset">
                    <FormLabel component="legend">Is Single Select</FormLabel>
                    <RadioGroup
                      row
                      name="is_single_select"
                      value={formik.values.is_single_select}
                      onChange={(event) =>
                        formik.setFieldValue(
                          "is_single_select",
                          event.target.value === "true"
                        )
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend">Is Mandatory </FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.is_mandatory}
                            onChange={(event) =>
                              formik.setFieldValue(
                                "is_mandatory",
                                event.target.checked
                              )
                            }
                          />
                        }
                        label={formik.values.is_mandatory ? "Yes" : "No"}
                      />
                    </FormGroup>
                  </FormControl>

                  {renderOptionAttributes()}
                  <Button onClick={handleAddOptionAttribute}>Add Option</Button>
                </>
              )}
            </>

            <>
              {/* <Select
                label="Category"
                value={formik.values.category_id}
                onChange={(event) =>
                  formik.setFieldValue("category_id", event.target.value)
                }
              >
                {categories.map((category: any) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select> */}
            </>

            {isEdit === true ? (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                  color: (theme) => theme.palette.custom?.buttonColor,
                }}
                // onClick={handleSaveChanges}
                disabled={formik.isSubmitting || sendLoading}
              >
                {saveButtonLabel}
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  bgcolor: (theme) => theme.palette.custom?.buttonBgColor,
                  color: (theme) => theme.palette.custom?.buttonColor,
                }}
                disabled={isSubmitting}
              >
                {saveButtonLabel}
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Container>
  );
};

export default FormFields;
