import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "utils/baseUrl";

type Types = {
  handleClose: () => void;
  onAddSupply: any;
  id: any;
};

export default function CreateAddOns({ handleClose, onAddSupply, id }: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();
  const [addOns, setAddOns] = useState([]);

  const fetchAddOns = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${baseUrl}/add_ons`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAddOns(response.data);
    } catch (error) {
      console.error("Error fetching add-ons:", error);
    }
  };

  useEffect(() => {
    fetchAddOns();
  }, []);
  // console.log("==================categoriesData==================");
  // console.log(categoriesData);
  // console.log("====================================");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      food_item_id: id,
      name: "",
      // description: "",
      is_single_select: false,
      is_mandatory: false,
      options_attributes: [],
    },
    // validationSchema,
    onSubmit: async (values) => {
      // console.log('==================values==================');
      // console.log(values);
      // console.log('====================================');
      const formattedData = {
        ...values,
        options_attributes: values.options_attributes.map((option) => ({
          name: option.name,
          is_per_unit: option.is_per_unit,
          // is_mandatory: option.is_mandatory,
          price: Number(option.price),
        })),
      };

      const submittedData = await sendData({
        endpoint: "copied_add_ons",
        data: formattedData,
        isJson: true,
        method: "post",
      });
      if (submittedData) {
        onAddSupply(submittedData);
        handleClose();
      }
    },
  });

  // const handleDropdownChange = (setterFunction: any) => (newValue: any) => {
  //   setterFunction(newValue);
  // };

  // const handleDropdownChangeCategory = handleDropdownChange(
  //   setSelectedCategories
  // );

  const errorToDisplay = sendError;

  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        formTitle={"Add New addons"}
        saveButtonLabel={"Create addons"}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        addOns={addOns}
      />
    </>
  );
}
