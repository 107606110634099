import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";
import { useEffect, useState } from "react";
import { baseUrl } from "utils/baseUrl";
import axios from "axios";

type Types = {
  handleClose: () => void;
  onUpdateSuccess: any;
  selectedCategory: any;
};

export default function UpdateAddOns({
  handleClose,
  onUpdateSuccess,
  selectedCategory,
}: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();
  const [addOns, setAddOns] = useState([]);

  const fetchAddOns = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const response = await axios.get(`${baseUrl}/add_ons`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAddOns(response.data);
    } catch (error) {
      console.error("Error fetching add-ons:", error);
    }
  };
  useEffect(() => {
    fetchAddOns();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedCategory?.name ?? "",
      // description: selectedCategory?.description ?? "",
      is_single_select: selectedCategory?.is_single_select ?? false,
      is_mandatory: selectedCategory?.is_mandatory ?? false,
      options_attributes: selectedCategory?.options ?? [],
    },

    // validationSchema,
    onSubmit: async (values) => {
      const optionsToSubmit = values.options_attributes.map((option: any) => {
        const { id, ...optionData } = option;
        return id.toString().length === 36 ? optionData : { ...option, id };
      });

      // const formattedData2 = {
      //   ...values,
      //   options_attributes: values.options_attributes.map((option: any) => ({
      //     id: option.id,
      //     name: option.name,
      //     is_per_unit: option.is_per_unit,
      //     price: Number(option.price),
      //   })),
      // };

      const formattedData = {
        name: values.name,
        is_single_select: values.is_single_select,
        is_mandatory: values.is_mandatory,
        options_attributes: optionsToSubmit,
      };

      try {
        const submittedData = await sendData({
          endpoint: `copied_add_ons/${selectedCategory.id}`,
          data: formattedData,
          isJson: true,
          method: "put",
        });

        if (submittedData) {
          onUpdateSuccess(submittedData);
          handleClose();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const errorToDisplay = sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        formTitle={"Update addons"}
        saveButtonLabel={"Update addons"}
        selectedCategory={selectedCategory}
        onUpdateSuccess={onUpdateSuccess}
        addOns={addOns}
        // isEdit={true}
      />
    </>
  );
}
