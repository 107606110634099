import { styled, Theme, CSSObject, useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";

import {
  Box,
  Drawer as MuiDrawer,
  List,
  CssBaseline,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";

// import  brandLogo from "./../../assets/logo.png";
import DashboardIcon from '@mui/icons-material/Category';

// const brandLogo = require('./../../assets/logo.png');

const openedMixin = (theme: Theme, drawerWidth: number): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface SidebarProps {
  open: boolean;
  drawerWidth: number;
}

interface DrawerProps {
  theme: Theme;
  open: boolean;
  drawerWidth: number;
}

type OpenSubMenus = {
  [key: number]: boolean;
};

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }: DrawerProps) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    "& .MuiDrawer-paper": openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const list = [
  { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
  // { text: "User Management", icon: <GroupIcon />, path: "/usersmanagement" },
  { text: "Categories", icon: <CategoryIcon />, path: "/categories" },
  { text: "Food Items", icon: <CategoryIcon />, path: "/fooditems" },
  { text: "Add Onss", icon: <CategoryIcon />, path: "/addons" },
  {
    text: "FoodItems Carousels",
    icon: <CategoryIcon />,
    path: "/fooditemscarousels",
  },
];

export default function Sidebar({ open, drawerWidth }: SidebarProps) {
  // const [isCollapse, setIsCollapse] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openSubMenus, setOpenSubMenus] = useState<OpenSubMenus>({});

  // const handleClick = () => {
  //   setIsCollapse(!isCollapse);
  // };

  const theme = useTheme();
  const navigate = useNavigate();
  let location = useLocation();

  const handleItemClick = (
    item: { path: string; subItems?: any[] },
    index: number
  ) => {
    if (!item.subItems) {
      navigate(item.path);
    } else {
      setOpenSubMenus((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    }
  };

  const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
    minWidth: 0,
    mr: theme.spacing(3),
    justifyContent: "center",
    color: theme.palette.custom?.sideIcon,
  }));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Drawer
        theme={theme}
        variant="permanent"
        open={open}
        drawerWidth={drawerWidth}
        PaperProps={{
          sx: {
            // bgcolor: theme.palette.primary.main,
            bgcolor: theme.palette.custom.sidebarColor,
          },
        }}
      >
        <DrawerHeader>
          {/* <img src={brandLogo} alt="" width={"60%"} /> */}
          <img src={require("./../../assets/logo.png")} alt="" width={open ? "60%" : "50px"} />
        </DrawerHeader>
        <Divider />

        <List>
          {list.map((item, index) => {
            return (
              <>
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                    bgcolor:
                      location.pathname === item.path
                        ? theme.palette.custom?.activeLink
                        : null,
                  }}
                >
                  <ListItemButton
                    component="a"
                    // href={item.path}
                    href={`#${item.path}`}
                    rel="noopener noreferrer"
                    // onClick={() => {
                    //   navigate(item.path);
                    // }}
                    // onClick={() => handleItemClick(item, index)}
                    onClick={(e) => {
                      e.preventDefault();
                      handleItemClick(item, index);
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        // bgcolor: theme.palette.custom?.sideIcon,
                      }}
                    >
                      <CustomListItemIcon>{item.icon}</CustomListItemIcon>
                    </ListItemIcon>

                    <ListItemText
                      primary={item.text}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            );
          })}
        </List>
      </Drawer>
    </Box>
  );
}
