import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";
import validationSchema from './../validationSchema';

type Types = {
  handleClose: () => void;
  onAddSupply: any;
};

export default function CreateAddOns({ handleClose, onAddSupply }: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();

  // console.log("==================categoriesData==================");
  // console.log(categoriesData);
  // console.log("====================================");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      description: "",
      is_single_select: false,
      is_mandatory: false,
      options_attributes: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      const formattedData = {
        add_on: {
          name: values.name,
          description: values.description,
          is_single_select: values.is_single_select,
          is_mandatory: values.is_mandatory,
          // options_attributes: values.options_attributes,
          options_attributes: values.options_attributes.map((option) => ({
            name: option.name,
            is_per_unit: option.is_per_unit,
            price: Number(option.price),
          })),
        },
      };

      const submittedData = await sendData({
        endpoint: "add_ons",
        data: formattedData,
        isJson: true,
        method: "post",
      });
      if (submittedData) {
        onAddSupply(submittedData);
        handleClose();
      }
    },
  });

  // const handleDropdownChange = (setterFunction: any) => (newValue: any) => {
  //   setterFunction(newValue);
  // };

  // const handleDropdownChangeCategory = handleDropdownChange(
  //   setSelectedCategories
  // );

  const errorToDisplay = sendError;

  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        formTitle={"Add New addons"}
        saveButtonLabel={"Create addons"}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
      />
    </>
  );
}
