import FormFields from "../FormFields";
import ErrorDisplay from "Components/ErrorAndLoading/ErrorDisplay";
import useSendData from "Components/Axios/SendData";
import { useFormik } from "formik";
import Loading from "Components/ErrorAndLoading/Loading";

type Types = {
  handleClose: () => void;
  onUpdateSuccess: any;
  selectedCategory: any;
};

export default function UpdateAddOns({
  handleClose,
  onUpdateSuccess,
  selectedCategory,
}: Types) {
  const { sendData, error: sendError, isLoading: sendLoading } = useSendData();
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      name: selectedCategory?.name ?? "",
      description: selectedCategory?.description ?? "",
      is_single_select: selectedCategory?.is_single_select ?? false,
      is_mandatory: selectedCategory?.is_mandatory ?? false,
      options_attributes: selectedCategory?.options ?? [],
    },

    // validationSchema,
    onSubmit: async (values) => {
      const optionsToSubmit = values.options_attributes.map((option: any) => {
        const { id, ...optionData } = option;
        return id.toString().length === 36 ? optionData : { ...option, id };
      });

      // const formattedOptions = values.options_attributes.map((option: any) => ({
      //   id: option.id,
      //   name: option.name,
      //   is_per_unit: option.is_per_unit,
      //   price: Number(option.price),
      // }));

      const formattedData = {
        add_on: {
          name: values.name,
          description: values.description,
          is_single_select: values.is_single_select,
          is_mandatory: values.is_mandatory,
          options_attributes: optionsToSubmit,
        },
      };

      try {
        const submittedData = await sendData({
          endpoint: `add_ons/${selectedCategory.id}`,
          data: formattedData,
          isJson: true,
          method: "put",
        });

        if (submittedData) {
          onUpdateSuccess(submittedData);
          handleClose();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const errorToDisplay = sendError;
  return (
    <>
      <>
        <ErrorDisplay error={errorToDisplay} />
        <Loading isLoading={sendLoading} />
      </>
      <FormFields
        formik={formik}
        isSubmitting={formik.isSubmitting}
        handleClose={handleClose}
        formTitle={"Update addons"}
        saveButtonLabel={"Update addons"}
        selectedCategory={selectedCategory}
        onUpdateSuccess={onUpdateSuccess}
      />
    </>
  );
}
